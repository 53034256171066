// export const BASE_URL = "http://52.52.45.58:7001" // Development
export const BASE_URL = "https://insuregora.com:7001" // Production
// export const BASE_URL = "http://127.0.0.1:7001" // Local

export const LIST_ALL_CATEGORY = `${BASE_URL}/category/list`
export const LIST_BY_CATEGORY = `${BASE_URL}/subcategory/listbycategory/`
export const HEADER_MENU_LIST = `${BASE_URL}/category/headermenulist`
export const LIST_ALL_BANNERS = `${BASE_URL}/banners/list`
export const LIST_ALL_COMPARE_COMPANY = `${BASE_URL}/compare-company/list`
export const LIST_ALL_FAQ = `${BASE_URL}/faqs/list`
export const LIST_WHY_TRUST = `${BASE_URL}/why_trust/list`
export const GET_CATEGORY_INFO_BY_SLUG = `${BASE_URL}/category/get-info-by-slug/`
export const GET_PLAN_FORM_BY_SLUG = `${BASE_URL}/subcategory/getfields/`
export const LIST_FIRST_ROW_ARTICLE = `${BASE_URL}/article/first-row/`
export const LIST_ALL_ARTICLE = `${BASE_URL}/article/list/`
export const GET_ARTICLE_INFO_BY_SLUG = `${BASE_URL}/article/get-info-by-slug/`
export const GET_SIMILAR_ARTICLE_BY_SLUG = `${BASE_URL}/article/get-similar-by-slug/`
export const GET_ABOUT_DATA = `${BASE_URL}/about/get-page-data/`
export const POST_FREE_QUOTE_DATA = `${BASE_URL}/quote/getfreequotes`
export const POST_ARTICLE_COMMENT = `${BASE_URL}/general/postblogcomments`
export const GET_SETTING_DATA = `${BASE_URL}/setting/get-data`
export const GET_SUBCATEGORY_LIST = `${BASE_URL}/subcategory/get-list`
export const GET_RESULT = `${BASE_URL}/subcategory/getresult`
export const GET_APPLY_PROCESS_PAGE = `${BASE_URL}/apply-process/get-page-data`
export const GET_SUBCATEGORY_INFO_BY_SLUG = `${BASE_URL}/subcategory/get-info-by-slug/`
export const GET_RELETED_SUBCATEGORY = `${BASE_URL}/subcategory/related/`
export const GET_RESULT_PAGE_DATA = `${BASE_URL}/result/get-page-data`
export const GET_ALL_STATES = `${BASE_URL}/general/state`
export const GET_ALL_CITY_BY_STATE = `${BASE_URL}/general/city/`
export const GET_LIST_JOB_POSTS = `${BASE_URL}/job-posts/list`
export const POST_APPLY_JOB = `${BASE_URL}/job-applicant/submit`
export const GET_PAGE_DATA = `${BASE_URL}/pages/`



